/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-01",
    versionChannel: "nightly",
    buildDate: "2024-04-01T00:05:49.860Z",
    commitHash: "df7d95bf431911ef88433e5a253a36fc738af8cf",
};
